<template>
  <div>
    <div class="mb-4">
      <div class="headline">Welcome {{ user.first_name }}</div>
      <div class="subtitle-1 light-grey">Quick view of your data</div>
    </div>
    <influencer-dashboard-tabs></influencer-dashboard-tabs>
  </div>
</template>
<script>
import { mapState } from "vuex";
import InfluencerDashboardTabs from "../dashboard/InfluencerDashboardTabs";

export default {
  components: { InfluencerDashboardTabs },
  computed: {
    ...mapState("core/auth", ["user"])
  },
};
</script>
