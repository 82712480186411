<template>
  <v-tabs v-model="selectedTab" grow show-arrows class="elevate-tab-headers">
    <v-tabs-slider color="accent"></v-tabs-slider>

    <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
      {{ tab.text }}
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>

    <v-tabs-items
      v-model="selectedTab"
      touchless
      class="elevation-0 transparent"
    >
      <v-tab-item v-for="item in tabs" :key="item.name">
        <influencer-promotion-tab
          v-if="item.name === 'promoted'"
        ></influencer-promotion-tab>
        <current-month-tab
          v-else-if="item.name === 'current_month'"
        ></current-month-tab>
        <monthly-tab v-else-if="item.name === 'monthly'"></monthly-tab>
        <yearly-tab v-else-if="item.name === 'yearly'"></yearly-tab>
        <v-alert v-else :value="true" type="warning"
          >Under construction</v-alert
        >
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import CurrentMonthTab from "../../common/dashboard/CurrentMonthTab.vue";
import MonthlyTab from "../../common/dashboard/MonthlyTab.vue";
import YearlyTab from "../../common/dashboard/YearlyTab.vue";
import InfluencerPromotionTab from "../../common/dashboard/InfluencerPromotionTab.vue";

export default {
  components: {
    InfluencerPromotionTab,
    MonthlyTab,
    YearlyTab,
    CurrentMonthTab
  },
  props: {},
  data: () => ({
    selectedTab: null
  }),
  computed: {
    tabs() {
      let tabs = [
        { name: "promoted", text: "Promoted" },
        { name: "current_month", text: "This month" },
        { name: "monthly", text: "Monthly" },
        { name: "yearly", text: "Yearly" }
      ];
      return tabs;
    }
  }
};
</script>
